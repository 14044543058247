import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Slide,
    Stack,
    useMediaQuery, useTheme
} from "@mui/material";
import {NavLink, Link} from "react-router-dom";

const pages = [
    {
        id: 'home',
        title: 'Home',
        url: '/',
        img: require('../../assets/images/icon-home.png')
    },
    {
        id: 'foto',
        title: 'Foto/Video',
        url: '/foto-e-video',
        img: require('../../assets/images/icon-foto-e-video.png')
    },
    {
        id: 'programma',
        title: 'Programma di viaggio',
        url: '/programma',
        img: require('../../assets/images/icon-program.png')
    },
    {
        id: 'hotel-voli',
        title: 'Hotel e voli',
        url: '/hotel-e-voli',
        img: require('../../assets/images/icon-plane.png')
    },
    {
        id: 'info-utili',
        title: 'Informazioni utili',
        url: '/informazioni-utili',
        img: require('../../assets/images/icon-info.png')
    },
    {
        id: 'dress-code',
        title: 'dress code',
        url: '/dress-code',
        img: require('../../assets/images/icon-dress.png')
    },
    {
        id: 'contatti',
        title: 'contatti',
        url: '/contatti',
        img: require('../../assets/images/icon-contacts.png')
    }
]

export const Header = () => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    const drawer = (
        <Box>
            <Toolbar/>
            <Divider/>
            <Stack spacing={4} direction={'column'} sx={{width: '100%', height: '100%', p: 2}} alignItems={'left'}>
                {pages.map((page) => (
                    <Stack className={'menu-button'} direction={'row'} spacing={2} alignItems={'center'}
                           component={NavLink} to={page.url} key={page.id}>
                        <Box sx={{width: '1.5rem'}}>
                            <img src={page.img} style={{width: '100%'}}/>
                        </Box>
                        <Typography textTransform={'uppercase'} fontSize={14}>
                            {page.title}
                        </Typography>

                    </Stack>
                ))}
            </Stack>
        </Box>
    );


    return (
        <Box sx={{flexGrow: 1, pb: 10}}>
            {matches && <Drawer
                variant="temporary"
                open={open}
                anchor={'right'}
                onClose={() => setOpen(!open)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', lg: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: 240},
                }}
            >
                {drawer}
            </Drawer>}
            <AppBar position={'fixed'} sx={{background: 'white', dropShadow: 'none', borderBottom: '2px solid #2F6CB4'}}
                    elevation={0}>
                <Toolbar>
                    <Stack justifyContent={'space-between'} direction={'row'} sx={{width: '100%', py: 2}}
                           alignItems={'center'}>
                        <Box component={Link} to={'/'}>
                            <Box sx={{width: '6rem'}}>
                                <img style={{width: '100%'}} src={require('../../assets/images/logo-edenred.png')}
                                     alt={'logo'}/>
                            </Box>
                        </Box>
                        <Box sx={{position: 'relative', flexGrow: 1}}>
                            <Slide direction={'left'} in={open} mountOnEnter unmountOnExit
                                   sx={{
                                       position: 'absolute',
                                       right: '0',
                                       width: '100%',
                                       height: '100%',
                                       display: {xs: 'none', lg: 'flex'},
                                   }}>
                                <Stack spacing={2.5} direction={'row'} justifyContent={'end'}
                                       sx={{width: '100%', height: '100%'}} alignItems={'center'}>
                                    {pages.map((page) => (
                                        <Stack className={'menu-button'} direction={'row'} spacing={2}
                                               alignItems={'center'} component={NavLink} to={page.url} key={page.id}>
                                            <Box sx={{width: '1.5rem'}}>
                                                <img src={page.img} style={{width: '100%'}}/>
                                            </Box>
                                            <Typography textTransform={'uppercase'} fontSize={13}>
                                                {page.title}
                                            </Typography>

                                        </Stack>
                                    ))}
                                </Stack>
                            </Slide>
                            <Slide in={!open} direction={"left"} appear={false}
                                   sx={{position: 'absolute', right: '0', width: '100%', height: '100%'}}>
                                <Stack direction={'row'} justifyContent={'end'} sx={{width: '100%', height: '100%'}}
                                       alignItems={'center'}>
                                    <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={24}
                                                onClick={() => setOpen(!open)}
                                                sx={{
                                                    color: '#EF4231',
                                                    '&:hover': {color: '#e2716f'},
                                                    cursor: 'pointer'
                                                }}>
                                        Menu
                                    </Typography>
                                </Stack>
                            </Slide>
                        </Box>
                    </Stack>
                </Toolbar>
            </AppBar>
        </Box>

    )
}
