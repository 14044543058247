import React from "react";
import {Box, Card, Divider, Collapse, Stack, Typography, CardContent} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

export const VoloCollapse = ({volo}) => {
    const [open, setOpen] = React.useState(false);

    return (
        <Card sx={{border:'1px solid #dedede'}}>
            <Stack onClick={() => setOpen(!open)} sx={{cursor: 'pointer', p:2}} direction={'row'}
                   justifyContent={'space-between'}>
                <Typography textTransform={'uppercase'} color={'#2F6CB4'} fontWeight={'bold'}>
                    COMPAGNIA AEREA: {volo.compagnia}
                </Typography>
                <Box>
                    {open ? <ExpandLess/> : <ExpandMore/>}
                </Box>
            </Stack>
            <Collapse in={open}>
                <Divider/>
                <CardContent>
                    <Box>
                        {volo.content}
                    </Box>
                    <Box sx={{pt:2}}>
                        <Typography fontWeight={'bold'}>
                            POLICY BAGAGLIO {volo.compagnia.toUpperCase()} <a href={volo.policyLink} target={'_blank'}>(Regole generali bagagli {volo.compagnia.toUpperCase()})</a>
                        </Typography>
                        {volo.policyBagaglio}
                    </Box>
                </CardContent>
            </Collapse>
        </Card>
    )
}
