import React from "react";
import {formMap} from "../assets/data/formMap";
import {Box, Typography, Grid} from "@mui/material";
import {FormField} from "./FormField";

export const FormContent = ({formik}) => {
    return (
        <Box>
            {formMap.map((section, index) => {
                /*if (section.conditions) {
                    let render = []
                    section.conditions.predicates.forEach(predicate => {
                        let checked = formik.values[predicate.id] === predicate.value
                        render.push(checked)
                    })
                    if(render.includes(true)) {

                        return <Box key={index}>
                            <Typography fontWeight={'bold'}>{section.title}</Typography>
                            <Grid container spacing={2}>
                                {section.fields.map((field, index) =>
                                    {
                                        if (field.conditions) {
                                            let render = []
                                            field.conditions.predicates.forEach(predicate => {
                                                let checked = formik.values[predicate.id] === predicate.value
                                                render.push(checked)
                                            })
                                            if(render.includes(true)) {
                                                return <FormField formik={formik} key={index} name={field.name} id={field.id}
                                                                  label={field.label}
                                                                  type={field.type} values={field.values ?? []}
                                                                  cols={field.cols}
                                                                  disablePast={field.disablePast}
                                                                  disableFuture={field.disableFuture}
                                                />
                                            }
                                        } else {
                                            return <FormField formik={formik} key={index} name={field.name} id={field.id}
                                                       label={field.label}
                                                       type={field.type} values={field.values ?? []}
                                                       cols={field.cols}
                                                       disablePast={field.disablePast}
                                                       disableFuture={field.disableFuture}
                                            />
                                        }
                                    }
                                )}
                            </Grid>
                        </Box>
                    }
                } else {*/
                    return <Box key={index} sx={{py:2}}>
                        <Typography fontWeight={'bold'}>{section.title}</Typography>
                        <Grid container spacing={2}>
                            {section.fields.map((field, index) =>
                                <FormField formik={formik} key={index} name={field.name} id={field.id} label={field.label}
                                           type={field.type} values={field.values ?? []} cols={field.cols}
                                           disablePast={field.disablePast}
                                           disableFuture={field.disableFuture}
                                           longText={field.longText}
                                />
                            )}

                        </Grid>
                    </Box>
                /*}*/
            })}
        </Box>
    )
}
