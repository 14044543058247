export const contactsMap = [
    {
        title: 'TOUR LEADER (referenti in aeroporto)',
        list: [
            <>
                <b>COMPAGNIA AEREA: EASYJET</b>
                <br/>
                RICCARDO MORA - cell. 342 563 8403
            </>,
            <>
                <b>COMPAGNIA AEREA: ITA AIRWAYS</b>
                <br/>
                LAURA CARLI - cell. 327 109 5373
                <br/>
                SILVIA MUFFOLINI - cell. 347 087 6244
            </>,
            <>
                <b>COMPAGNIA AEREA: VOLOTEA</b>
                <br/>
                MONIA CIURCOVICH - cell. 344 063 7985
            </>
        ]
    },
    {
        title: 'ASSISTENTI AEROPORTUALI',
        list: [
            <>
                <b>COMPAGNIA AEREA: ITA AIRWAYS + EASYJET (AEROPORTO MILANO MALPENSA)</b>
                <br/>
                MANUELA GREGORI - cell. 339 545 8940
            </>,
            <>
                <b>COMPAGNIA AEREA: VOLOTEA (AEROPORTO NAPOLI CAPODICHINO)</b>
                <br/>
                CRISTINA DI LAURO - cell. 333 272 2007
            </>,
        ]
    },
    {
        title: 'STAFF IN LOCO',
        list: [
            "CHIARA BABINI - cell. 347 500 2100",
            "TIZIANA TESSITORE - cell. 327 408 7494"

        ]
    },
    {
        title: 'NUMERO DI SERVIZIO',
        list: [
            "Cell. 334 666 3953"
        ]
    },
    {
        title: 'SEGRETERIA ORGANIZZATIVA',
        description:<>
            E-mail: <a href={"mailto:segreteria@edenredsalesmeeting2023.it"} target={'_blank'}>segreteria@edenredsalesmeeting2023.it</a>
            <br/>
            Tel. 0662206863
        </>
    },
    {
        title: 'TRANSFER A MYKONOS',
        description:<>
            Per qualsiasi necessità/richiesta di trasferimenti individuali durante l’evento, potrai rivolgerti al servizio concierge dell’hotel di appartenenza.
            <br/><br/>
            Hotel Imperial – tel. +30 22890 79500
            <br/>
            Hotel Utopia – tel. +30 22890 76069
            <br/>
            Hotel Villa Collection – tel. +30 22890 76000
            <br/>
            Hotel Royal – tel. +30 22890 72000
        </>
    }
]
