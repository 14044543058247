export const homeMap = [
    {
        title:"MYKONOS",
        sections:[
            {
                title:"La destinazione",
                description:<>Un tempo semplice <b>villaggio di pescatori e di mercanti di passaggio</b>, Mykonos ha oggi molti volti. Nota per la movida e il divertimento notturno, l’isola è ricca di contenuto e di bellezza: vicoli stretti dai balconi traboccanti di fiori, casette bianche arroccate una sull’altra, una tradizione di canti antichi e una storia affascinante e misteriosa. </>
            }
        ]
    },
    {
        title:"STORIA e PAESAGGIO",
        sections:[
            {
                title:"LA FAMOSA “PICCOLA VENEZIA”",
                description: <>Oggi Mykonos viene definita un paradiso, senza dubbio <b>una delle isole greche più famose e belle al mondo. Per secoli è stata unicamente di passaggio per la sua vicinanza a Delos.</b><br/>La città era molto prosperosa e ricca prima del 88 a.c, anno in cui venne rasa al suolo Delos. Venne conquistata dalla tirannia bizantina per poi cadere in mano alla Repubblica di Venezia dopo la resa di Costantinopoli.<br/><b>Ancora oggi uno dei quartieri più caratteristici dell’isola, infatti, è la “Piccola Venezia”</b>, il cui nome si deve ai caseggiati che si affacciano senza barriere direttamente sul mar Egeo, a filo sull’acqua, proprio come a Venezia.</>,
                image:"home-piccola-venezia"
            },
            {
                title:"LA MYKONOS DEI MULINI A VENTO",
                description: <><b>Lo skyline di Mykonos è riconoscibile in maniera univoca e immediata grazie ai suoi mulini</b>, che a partire dal XVI secolo sono stati la più grande fonte economica dell'isola. Un tempo la sua produzione di grano riusciva a soddisfare sia la popolazione presente che le richieste esterne. Infatti da quando il turismo è diventato la fonte primaria di guadagno, i mulini sono stati dismessi e riqualificati come punto panoramico. <b>Dei sedici mulini originali purtroppo ne sono rimasti oggi solo cinque.</b></>,
                image:"home-mulini"
            }
        ]
    },
    {
        title:"TRADIZIONI e FOLKLORE",
        sections:[
            {
                title:"IL SIRTAKI",
                description:<>Il sirtaki è una danza popolare greca. Si tratta in realtà di una <b>creazione originale del compositore Mikīs Theodōrakīs scritta appositamente per il film Zorba il greco nel 1964</b> e che viene perciò anche chiamata “danza di Zorba”. Caratteristica principale della danza e della musica d'accompagnamento è il fatto che il ritmo, da principio lento e sincopato, vada via via accelerando.</>,
                image:"home-sirtaki"
            },
            {
                title:"SUPERSTIZIONI GRECHE",
                description:<>La tradizione vuole che per gelosia o invidia, attraverso falsi complimenti, sia possibile ricevere il malocchio. Per evitarlo viene indossata una piccola perla blu con un occhio bianco dipinto al centro. <b>Per questo prende il nome di “occhio greco” o “nazar” per i turchi</b> e ad oggi è anche una tipica decorazione da interni.
                    <br/>
                    Perché il colore è il blu? Nei paesi dell’Egeo gli occhi azzurri erano molto rari e iniziò a diffondersi la credenza che fossero portatori di malocchio. Il suo colore blu serve quindi a esorcizzare l’effetto del male.
                    <br/>
                    <b>Attenzione! L’usanza vuole che l’occhio greco non vada acquistato da sé, si accetta solo se regalato!
                        <br/>Inoltre, qualora si rompesse va immediatamente sostituito!</b>
                </>,
                image:"home-superstizioni-greche"
            }
        ]
    },
    {
        title:"GASTRONOMIA GRECA",
        sections:[
            {
                title:"L’ECCELLENZA OLTRETEMPO",
                description: <>La cucina greca ha una storia millenaria che dai tempi di Alessandro Magno in poi, divenne una vera e propria arte.
                    <br/>
                    È in Grecia che sono apparsi i primi cuochi di mestiere, generosamente retribuiti dai ricchi per i quali preparavano lauti banchetti.<br/>
                    <b>Negli anni, dopo la conquista di Costantinopoli da parte dei Turchi, la cucina ellenica ha subito l’influenza di quella ottomana. Basti pensare ad alcuni tradizionali piatti greci: tzatziki, souvlaki e mussaka e tanti altri.</b>
                </>,
                image:"home-eccellenza"
            },
            {
                title:"PÁME GIA KAFÉS?",
                description: <>Non solo gli italiani amano il caffè!
                    <br/>
                    <b>In Grecia bere un caffè è un rito</b> così abituale che l'invito <i>“páme gia kafés?”</i> (andiamo a prendere un caffè?) è ormai più comune del classico “usciamo”.
                    <br/>
                    Si tratta di un caffè bollito che risulta molto più diluito dell'espresso italiano, più simile a quello americano ma con un gusto molto più intenso.
                </>,
                image: "home-kafes"
            }
        ]
    },
    {
        title:"CURIOSITÀ",
        sections:[
            {
                title:"L’UCCELLO DEL BUON AUGURIO",
                description: <>Il <b>pellicano</b> è un simbolo importante di Mykonos.
                    <br/>
                    A metà degli anni ’50 una di queste creature fu ritrovata sull’isola da un pescatore che si prese cura delle sue ferite affinché potesse riprendere il suo viaggio migratorio verso il Nilo. Il pellicano, a cui venne dato il nome di <b>Petros</b>, una volta guarito, decise di non andarsene più, diventando un amico per gli abitanti del posto e un’attrazione per i viaggiatori di passaggio.
                    <br/>
                    <b>Venne considerato un simbolo di fortuna perché dopo il suo arrivo l'attività turistica aumentò notevolmente</b>, portando tanti visitatori.
                </>,
                image:"home-uccello-buon-augurio"
            }
        ]
    }
]
