export const infoMap = [
    {
        title: 'DOCUMENTI',
        description: <>È necessario viaggiare con uno dei due documenti in corso di validità: passaporto o carta d’identità valida per l’espatrio. <b>Sei tenuto/a a viaggiare con lo stesso documento inserito in fase di registrazione</b> poiché sono questi i dati che sono stati trasmessi a vettori aerei e strutture ricettive.</>,
    },
    {
        title: 'METEO',
        description: <>Le temperature medie alle isole Cicladi nel mese di settembre sono attorno ai 23,2°.
            A Mykonos il clima è mediterraneo, con inverni miti e piovosi ed estati calde e soleggiate.
            In estate, durante le ondate di caldo, quando in altre zone della Grecia si possono superare i 40 °C, qui la temperatura rimane in genere su valori più contenuti grazie al <i>meltemi</i>, un vento che riduce la sensazione di caldo e rende decisamente più piacevoli le escursioni sulle isole. Le precipitazioni sono scarsissime e sono concentrate nel periodo freddo, da ottobre a marzo, quando possono presentarsi sotto forma di rovescio o temporale.
            La temperatura del mare alle Cicladi non è mai molto elevata, perché il vento che soffia in estate tende a mantenerla bassa.
        </>,
    },
    {
        title: 'VALUTA E PRESE DI CORRENTE',
        description: 'La moneta corrente è l’Euro. Le carte di credito più comuni come Visa, MasterCard, Maestro, American Express sono generalmente accettate. Non occorrono adattatori poiché le prese sono le medesime che abbiamo in Italia.',
    },
    {
        title: 'TELEFONIA',
        description: 'Il prefisso per chiamare dalla Grecia l’Italia è 0039 mentre quello per chiamare dall’Italia la Grecia è 0030.',
    },
    {
        title: 'FUSO ORARIO',
        description: 'Il fuso orario in Grecia è di +1 ora rispetto all\'Italia.',
    },
]
