export const programMap = [
    {
        day: '21 settembre 2023',
        image: "programma-giorno-1.jpg",
        sections: [
            {
                title: 'Mattina',
                activities: [
                    "Arrivo presso l’hotel Myconian Collection",
                    <>Registrazione presso l’hospitality desk Edenred dell'hotel Royal (il check-in è previsto <b>dalle ore 15:00</b>, salvo disponibilità delle camere prima dell’orario indicato)</>,
                    <><i><b>ore 13:00</b></i> - Pranzo al ristorante Ambrosia Restaurant & Terrace presso l'hotel Royal</>,
                ]
            },
            {
                title: 'Pomeriggio',
                activities: [
                    "Tempo libero"
                ]
            },
            {
                title: 'Sera',
                activities: [
                    <><i><b>ore 19:30</b></i> - Appuntamento nella hall dell'hotel di appartenenza per trasferimento verso ristorante Mamalouka</>,
                    <><i><b>ore 20:30</b></i> - Cool & Glamour Greek Night Dinner</>,
                    <>Dopocena tra i locali di Mykonos</>,
                    <><i><b>dalle ore 23.30 fino alle ore 02.00</b></i> - Trasferimenti per l'hotel dal punto di rilascio dei pullman. I mezzi partiranno con frequenza ogni mezz’ora, a seconda del riempimento posti.</>

]
            }
        ]
    },
    {
        day: '22 settembre 2023',
        image: "programma-giorno-2.jpg",
        sections: [
            {
                title: 'Mattina',
                activities: [
                    <><i><b>dalle ore 07:30 alle ore 10:30</b></i> - Colazione presso l’hotel di appartenenza</> ,
                    <><i><b>ore 10:00</b></i> - Sales Meeting in sala Rhenia Conference presso l'hotel Imperial</>,
                    <><i><b>ore 13:00</b></i> - Pranzo al ristorante Celebrities - Meltemi & Terraces presso l'hotel Imperial</>
                ]
            },
            {
                title: 'Pomeriggio',
                activities: [
                    "Tempo libero"
                ]
            },
            {
                title: 'Sera',
                activities: [
                    <><i><b>ore 20:30</b></i> - White Party presso la piscina dell’hotel Imperial </>
                ]
            }
        ]
    },
    {
        day: '23 settembre 2023',
        image: "programma-giorno-3.jpg",
        sections: [
            {
                title: 'Mattina',
                activities: [
                    <><i><b>dalle ore 07:30 alle ore 10:30</b></i> - Colazione presso l’hotel di appartenenza e check-out</>,
                    <span>
                        Transfer e rientro dall'hotel di appartenenza secondo i seguenti orari:
                        <ul style={{paddingLeft:15}}>
                            <li><b>Volotea V71611<br/>ore 08.00</b> - partenza gruppo per rientro su Napoli</li>
                            <li><b>Easyjet U23666<br/>ore 12.45</b> - partenza gruppo per rientro su Milano Malpensa</li>
                            <li><b>Charter ITA Airways AZ9057<br/>ore 13.30</b> - partenza gruppo per rientro su Milano Malpensa</li>
                        </ul>
                    </span>
                ]
            }
        ]
    }
]
