import React from "react";
import {Website} from "../layouts/Website";
import {Box, Button, Container, Dialog, Divider, Grid, Stack} from "@mui/material";
import {fotoList} from "../assets/data/fotoList";
import {Masonry} from "@mui/lab";
import IconButton from "@mui/material/IconButton";
import {ArrowLeft, ArrowRight, Close, Download} from "@mui/icons-material";
import axios from "axios";


export const FotoEVideo = () => {
    const [selectedFoto, setSelectedFoto] = React.useState(null)

    function downloadImage(name) {
        let url = 'https://storage.googleapis.com/edenred-sales-meeting-23-public/photos/original/' + name;

        const img = new Image();
        img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
        // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#Implementing_the_save_feature
        img.src = url;
        img.onload = () => {
            // create Canvas
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            // create a tag
            const a = document.createElement('a');
            a.download = `${name}`;
            a.href = canvas.toDataURL('image/jpeg');
            a.click();
        };
        /*fetch(url, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function(buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "image.png"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(err => {
                console.log(err);
            });*/
    }


    const handleKeyDown = (event) => {
        console.log(event.key)
        if (event.key === 'ArrowLeft') {
            setSelectedFoto(selectedFoto - 1 < 0 ? 0 : selectedFoto - 1)
        }
        if (event.key === 'ArrowRight') {
            setSelectedFoto(selectedFoto + 1 > fotoList.length - 1 ? fotoList.length - 1 : selectedFoto + 1)
        }
    }

    return (
        <Website>
            <Dialog open={selectedFoto !== null} onClose={() => setSelectedFoto(null)} maxWidth={'lg'} onKeyDown={handleKeyDown}>
                <Box>
                    <IconButton onClick={() => setSelectedFoto(null)}
                                sx={{position:'absolute', right:'1rem', top:'1rem', background:'#EF4231', color:'white',
                                    '&:hover': {
                                        background: 'white',
                                        color: '#EF4231'
                                    }
                                }}>
                        <Close/>
                    </IconButton>
                </Box>
                <Box sx={{width:'min(60rem, 100%)', height:'40rem', margin:'auto', objectFit:'contain'}}>
                    <Stack sx={{position:'absolute', top:'47%', width:'100%', transform:'translate(0, -50%)', px:2}} direction={'row'} justifyContent={'space-between'}>
                        <Box>
                            <IconButton disabled={selectedFoto - 1 < 0} size={'large'} onClick={() => setSelectedFoto(selectedFoto - 1 < 0 ? 0 : selectedFoto - 1)}
                                        sx={{background:'#EF4231', color:'white',
                                            '&:hover': {
                                                background: 'white',
                                                color: '#EF4231'
                                            }
                                        }}>
                                <ArrowLeft/>
                            </IconButton>
                        </Box>
                        <Box>
                            <IconButton disabled={selectedFoto + 1 > fotoList.length - 1} size={'large'} onClick={() => setSelectedFoto(selectedFoto + 1 > fotoList.length - 1 ? fotoList.length - 1 : selectedFoto + 1)}
                                        sx={{background:'#EF4231', color:'white',
                                            '&:hover': {
                                                background: 'white',
                                                color: '#EF4231'
                                            }
                                        }}>
                                <ArrowRight/>
                            </IconButton>
                        </Box>
                    </Stack>
                    <img style={{width:'100%', height:'100%', objectFit:'contain'}} src={'https://storage.googleapis.com/edenred-sales-meeting-23-public/photos/original/' + fotoList[selectedFoto]} alt={'foto'}/>
                </Box>
                <Box sx={{textAlign:'center', p:2}}>
                    <Button variant={'contained'} startIcon={<Download/>} onClick={() => downloadImage(fotoList[selectedFoto])}>
                        Download
                    </Button>
                </Box>
            </Dialog>
            <Container maxWidth={'md'} sx={{pt:4,pb:2}}>
                <Box sx={{width:'100%'}}>
                    <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                        <iframe
                            src={"https://player.vimeo.com/video/871503789?h=e2e2a2e339&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"}
                            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                        ></iframe>
                    </div>
                </Box>
            </Container>
            <Box sx={{background:'#2F6CB4', height:'2px'}}/>
            <Container maxWidth={'lg'} sx={{py:2}}>
                <Masonry columns={{xs:2, md:3, lg:4}} spacing={2}>
                    {
                        fotoList.map((foto, index) =>
                            <Box key={index}
                                 onClick={() => setSelectedFoto(index)}
                                 sx={{
                                '&:hover':{
                                    cursor:'pointer',
                                    transform:'scale(1.1)',
                                    transition:'all 0.1s ease-in-out',
                                }
                            }}>
                                <img style={{width:'100%'}} src={'https://storage.googleapis.com/edenred-sales-meeting-23-public/photos/thumbnail/' + foto} alt={'foto'}/>
                            </Box>
                        )
                    }
                </Masonry>
            </Container>
        </Website>
    )
}