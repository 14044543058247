import React from "react";
import {Box, Container, Typography} from "@mui/material";
import {Footer} from "../components/Footer";

export const Error = () => {
    return(
        <Box sx={{background:'#fff'}}>
            <Container sx={{pb: 2}}>
                <img src={require('../assets/header.jpg')} alt={'header'} style={{width: '100%'}}/>
                <Box sx={{textAlign: 'center', py:2, pb:5}}>
                    <Typography variant={'h5'} color={'red'} fontWeight={'bold'}>Il link utilizzato non è corretto</Typography>
                </Box>
            </Container>
            <Footer/>
        </Box>
    )
}
