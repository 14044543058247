import React from "react";
import {Grid, Typography} from "@mui/material";

export const HotelSection = () => {
  return(
      <Grid container spacing={2} sx={{pt:4}}>
          <Grid item xs={12} md={6}>
              <Typography variant={'h5'} color={'primary'} fontWeight={'bold'} sx={{pb:2}}>
                  HOTEL
              </Typography>
              <Typography variant={'h6'} fontWeight={'bold'} color={'#2F6CB4'}>
                  Myconian Collection (sezioni Royal, Utopia, Imperial e Villa Collection)
              </Typography>
              <Typography>
                  Elia Beach, Mikonos 846 00, Grecia
                  <br/>
                  Tel.  +30 2289 072000
                  <br/>
                  Sito web: <a href={'https://www.myconiancollection.gr'} target={'_blank'} style={{color:'black'}}>www.myconiancollection.gr</a>
              </Typography>
              <ul>
                  <li><Typography>Check-in a partire dalle ore 15:00</Typography></li>
                  <li><Typography>Check-out entro le ore 11:00</Typography></li>
                  <li><Typography>Colazione dalle ore 07:30 alle ore 10:30</Typography></li>
                  <li><Typography>Sistemazione in camera doppia con letti singoli. Servizi inclusi:</Typography></li>
                  <ul className={'dashed'}>
                      <li>Wi-fi gratuito</li>
                      <li>Servizio in camera (2 bottiglie di acqua minerale da 0,5 lt - rifornite giornalmente)</li>
                      <li>Accesso al centro fitness</li>
                      <li>Reception h24</li>
                      <li>Lettini e ombrelloni presso la spiaggia privata del resort</li>
                      <li>Sconto del 15% su bevande alcoliche consumate durante la cena all'Imperial Pool (lo sconto sarà applicato esclusivamente al momento del check-out)</li>
                  </ul>
              </ul>
          </Grid>
          <Grid item xs={12} md={6}>
              <img style={{width:'100%', borderRadius:'0.6rem'}} src={require('../../assets/images/hotel-myconian.jpg')}/>
          </Grid>
      </Grid>
  )
}
