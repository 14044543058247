import React from "react";
import {
    Box,
    Checkbox,
    FormControl, FormControlLabel,
    FormGroup, FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField, Typography,
    RadioGroup,
    Radio
} from "@mui/material";
import {DatePicker, DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {FastField, useField} from "formik";

export const FormField = ({label, ...props}) => {
    const [field, meta, helpers] = useField(props)

    return (
        <Grid item xs={props.cols ? props.cols.xs : 12} sm={props.cols ? props.cols.sm : 6}>
            {(() => {
                switch (props.type) {
                    case 'note':
                        return <Box>
                            <Typography>
                                {label}
                            </Typography>
                        </Box>
                    case 'checkbox':
                        return <FormControl sx={{pt: 2}} fullWidth error={!!(meta.touched && meta.error)}>
                            <FormGroup>
                                <FormControlLabel
                                    sx={{alignItems: 'start'}}
                                    control={<Checkbox sx={{pt: 0}} checked={field.value}
                                                       {...field}
                                                       {...props}
                                    />} label={label}
                                />
                            </FormGroup>
                            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                        </FormControl>
                    case 'radiogroup':
                        return <FormControl sx={{pt: 2}} variant="standard" fullWidth
                                            error={!!(meta.touched && meta.error)}>
                            {label && <FormLabel id={props.id} sx={{color: 'black !important'}}>{label}</FormLabel>}
                            <RadioGroup
                                aria-labelledby={props.id}
                                defaultValue={''}
                                {...field}
                                {...props}
                                row
                            >
                                {props.values?.map(v =>
                                    <FormControlLabel value={v.value} key={v.value} control={<Radio/>} label={v.label}/>
                                )}
                            </RadioGroup>
                            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                        </FormControl>
                    case 'select':
                        return <FormControl variant="standard" fullWidth error={!!(meta.touched && meta.error)}>
                            {label && <InputLabel id={field.id}>{label}</InputLabel>}
                            <Select
                                label={label ?? ''}
                                {...field}
                                {...props}
                                helperText={meta.touched && meta.error}
                            >
                                {props.values?.map(v =>
                                    <MenuItem value={v.value} key={v}>{v.label}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                        </FormControl>
                    case 'date':
                        return <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                name={props.id}
                                disableFuture={props.disableFuture}
                                disablePast={props.disablePast}
                                id={props.id}
                                sx={{width: '100%'}}
                                inputFormat={'DD/MM/YYYY'}
                                value={props.formik.values[props.id]}
                                onChange={(value) => {
                                    props.formik.setFieldValue(props.id, value, true);
                                }}
                                error={true}
                                label={label ?? ''}
                                renderInput={(params) =>
                                    <TextField
                                        variant={"standard"}
                                        name={props.id}
                                        id={props.id}
                                        fullWidth
                                        {...params}
                                        error={!!(props.formik.touched[props.id] && props.formik.errors[props.id])}
                                        helperText={props.formik.touched[props.id] && props.formik.errors[props.id]}
                                    />
                                }
                            />
                        </LocalizationProvider>
                    default:
                        return <TextField
                            error={!!(meta.touched && meta.error)}
                            helperText={meta.touched && meta.error}
                            {...field}
                            {...props}
                            multiline={props.longText}
                            minRows={props.longText ? 3 : 1}
                            variant={props.longText ? "outlined" : "standard"}
                            label={label ?? ''}
                            sx={{width: '100%'}}/>
                }
            })()}
        </Grid>
    )
}
