import React from "react";
import {Website} from "../layouts/Website";
import {Box, Container, Grid, Typography} from "@mui/material";
import {PageTitle} from "../components/shared/PageTitle";
import {HotelSection} from "../components/HotelEVoli/HotelSection";
import {VoliSection} from "../components/HotelEVoli/VoliSection";

export const HotelEVoli = () => {
    return(
        <Website>
            <Container maxWidth={'xxl'} sx={{pb:2, pt:6}}>
                <PageTitle title={'Hotel e voli'} image={'icon-plane'}/>
                <HotelSection/>
                <VoliSection/>
            </Container>
        </Website>
    )
}
