import React from "react";
import {Website} from "../layouts/Website";
import {PageTitle} from "../components/shared/PageTitle";
import {Box, Container, Typography} from "@mui/material";
import {infoMap} from "../assets/data/infoMap";

export const InformazioniUtili = () => {
    return (
        <Website>
            <Container maxWidth={'xxl'} sx={{pb: 2, pt: 6}}>
                <PageTitle title={'Informazioni utili'} image={'icon-info'}/>
                {
                    infoMap.map((info, index) =>
                        <Box key={index} sx={{py:1}}>
                            <Typography color={'#2F6CB4'} fontWeight={'bold'} variant={'h6'}>
                                {info.title}
                            </Typography>
                            <Typography>
                                {info.description}
                            </Typography>
                        </Box>
                    )}
            </Container>
        </Website>
    )
}
