import React from "react";
import {Box, Typography} from "@mui/material";

export const TestoLanding = ({userData}) => {
    return (
        <Box>
            <Typography textAlign={'center'} lineHeight={'1.6rem'}>
                Ciao {userData?.nome ?? ''},
                <br/>
                torna anche quest’anno il Sales Meeting Edenred
                <br/>
                in una nuova emozionante edizione: “To The Infinity and Beyond”!
                <br/><br/>
                Siamo lieti di invitarti <b>il 21-22-23 settembre 2023</b>
                <br/>
                a Mykonos
                <br/>
                presso l'Hotel Myconian Collection,
                <br/>
                per condividere i traguardi raggiunti e gli obiettivi futuri dei team Sales di Edenred
                <br/>
                in una destinazione che ci offre infiniti panorami sul mare.
            </Typography>
            <Typography lineHeight={'1.6rem'} sx={{py:3}}>
                <b>QUELLO CHE DEVI SAPERE:</b>
                <ul>
                    <li>
                        Ti anticipiamo che i voli diretti dall’Italia per Mykonos partono esclusivamente da due aeroporti: Milano (più di 200 posti) e Napoli (massimo 40 posti).
                        <br/>
                        L’idea è di far partire il centro-nord da Milano e il centro-sud da Napoli.
                        <br/>
                        <u>Per agevolare quindi i collegamenti, ti preghiamo di indicare nel form la tua città di provenienza
                            (domicilio).</u>
                        <br/>
                        Successivamente riceverai maggiori informazioni in merito al tuo volo.
                    </li>
                    <li>
                        Per vivere un’esperienza ancora più all’insegna della condivisione, ti informiamo che saranno
                        previste sistemazioni in camera doppia.
                        <br/>
                        <u>In una successiva comunicazione ti sarà chiesto di indicarci la persona con cui vorrai soggiornare.</u>
                    </li>
                    <li>
                        In caso di necessità la Segreteria Organizzativa è attiva dal lunedì al venerdì, dalle 09:00 alle 13:00 e
                        dalle 14:00 alle 18:00 all’indirizzo <a href={'mailto:segreteria@edenredsalesmeeting2023.it'} target={'_blank'}>segreteria@edenredsalesmeeting2023.it</a>
                    </li>
                </ul>
            </Typography>
            <Typography fontWeight={'bold'} lineHeight={'1.6rem'}>
                IL TUO HOTEL:
            </Typography>
            <Typography textAlign={'center'} lineHeight={'1.6rem'}>
                <br/>
                MYKONOS – Myconian Collection
                <br/>
                Elia Beach, Mykonos 846 00, Grecia
                <br/><br/>
                <b>Conferma la tua partecipazione!</b>
                <br/><br/>
                <u>*Se non ti sarà possibile partecipare per impegni personali o lavorativi,
                <br/>
                ti invitiamo a confrontarti direttamente con il tuo responsabile.</u>
            </Typography>
        </Box>
    )
}
