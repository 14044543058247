import React from "react";
import {Box, Typography} from "@mui/material";
import {voliMap} from "../../assets/data/voliMap.js";
import {VoloCollapse} from "./VoloCollapse";

export const VoliSection = () => {
  return(
      <Box sx={{pt:4}}>
          <Typography variant={'h5'} color={'primary'} fontWeight={'bold'} sx={{pb:2}}>
              VOLI
          </Typography>
          <Typography>
              In base ai voli a te assegnati tramite apposita comunicazione e-mail, puoi visionare di seguito gli orari di partenza e arrivo.
              <br/>
              <b>Scarica la lettera di convocazione in aeroporto ricevuta tramite e-mail.</b>
              <br/>
              Ricorda di stamparla e portarla con te il giorno della partenza!
              <ul>
                  <li><Typography>Lettera di convocazione - <a target={'_blank'} href={"/LETTERE_DI_CONVOCAZIONE_IN_AEROPORTO_EASYJET.pdf"}>Easyjet (.pdf)</a></Typography></li>
                  <li><Typography>Lettera di convocazione - <a target={'_blank'} href={"/LETTERE_DI_CONVOCAZIONE_IN_AEROPORTO_ITA_AIRWAYS.pdf"}>ITA Airways (.pdf)</a></Typography></li>
                  <li><Typography>Lettera di convocazione - <a target={'_blank'} href={"/LETTERE_DI_CONVOCAZIONE_IN_AEROPORTO_VOLOTEA.pdf"}>Volotea (.pdf)</a></Typography></li>
              </ul>
          </Typography>
          <Box sx={{width:'min(70rem, 100%)'}}>
              {
                    voliMap.map((volo, index) =>
                        <Box sx={{py:1}}>
                            <VoloCollapse key={index} volo={volo}/>
                        </Box>
                    )
              }
          </Box>
      </Box>
  )
}
