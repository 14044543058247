import React from "react";
import {Box} from "@mui/material";
import {Header} from "../components/shared/Header";

export const Website = ({children}) => {
    return(
        <Box>
            <Header/>
            {children}
        </Box>
    )
}
