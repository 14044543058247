import React, {useEffect} from "react";
import {Box, Button, CircularProgress, Container, Stack, Typography} from "@mui/material";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";
import {FormNonPartecipo} from "../components/FormNonPartecipo";
import {FormPartecipo} from "../components/FormPartecipo";
import {TestoLanding} from "../components/TestoLanding";
import {Footer} from "../components/Footer";

export const Register = () => {
    const {hash} = useParams()
    const navigate = useNavigate()
    const [{data: userData, loading, error}, refetch] = useAxios(`${API_URL}users/${hash}/info`, {manual: false})
    const [partecipo, setPartecipo] = React.useState(null)
    const [motivazione, setMotivazione] = React.useState('')
    const [partecipazione, setPartecipazione] = React.useState('')

    useEffect(() => {
        if(!loading) {
            if (error) {
                //navigate('/error', {replace: true})
            }
            if (userData) {
                setPartecipazione(userData.partecipazione ?? '')
            }
        }
    }, [userData, loading, error])

    const handleBack = () => {
        setPartecipo(null)
        setMotivazione('')
    }

    const handleChangeMotivazione = (event) => {
        setMotivazione(event.target.value)
    }

    const handleSetPartecipazione = (partecipazione) => {
        setPartecipazione(partecipazione)
    }

    return (
        <Box sx={{background:'#fff'}}>
            <Container sx={{pb: 12}}>
                <img src={require('../assets/header.jpg')} alt={'header'} style={{width: '100%'}}/>
                <Container sx={{py:10}}>
                    {loading ?
                        <Box sx={{textAlign: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :

                            <Container>
                                {(partecipo === null && partecipazione === '') && <Stack spacing={2} alignItems={'center'}>
                                    <Box>
                                        <TestoLanding userData={userData}/>
                                        <Box sx={{textAlign:'center', pt:4}}>
                                            <Button variant={'contained'} sx={{width: 'min(25rem, 100%)', fontSize: '1.4rem'}}
                                                    onClick={() => setPartecipo(true)}>
                                                Partecipo
                                            </Button>
                                        </Box>
                                    </Box>
                                </Stack>}
                                {(partecipo !== null && partecipazione === '') &&
                                    <Box>
                                        <Button variant={'outlined'} color={'secondary'} onClick={handleBack} startIcon={<ArrowBack/>}>Indietro</Button>
                                    </Box>
                                }
                                {(partecipo || partecipazione === 'si') &&
                                    <FormPartecipo partecipazione={partecipazione} handleSetPartecipazione={handleSetPartecipazione} userData={userData}/>}
                            </Container>
                    }
                </Container>
            </Container>
            <Footer/>
        </Box>
    )
}
