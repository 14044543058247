import React, {useEffect} from "react";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, capitalize, CircularProgress, Container, Stack, TextField, Typography} from "@mui/material";
import {Footer} from "../components/Footer";
import {keys} from "localforage";
import {ArrowBack} from "@mui/icons-material";

export const Accommodation = () => {
    const {hash} = useParams()
    const navigate = useNavigate()
    const [accommodation, setAccommodation] = React.useState(false)
    const [{data: userData, loading, error}, refetch] = useAxios(`${API_URL}users/${hash}/info`, {manual: false})
    const [form, setForm] = React.useState(false)
    const [sending, setSending] = React.useState(false)
    const [compagno, setCompagno] = React.useState({
        nomeCompagno: '',
        cognomeCompagno: '',
        emailCompagno: ''
    })
    const [{data: postResponse, loading: postLoading, error: postError}, executePost] = useAxios(
        {
            url: `${API_URL}accommodation/${hash}`, method: 'POST'
        }, {manual: true}
    )

    useEffect(() => {
        if (!loading) {
            if (error) {
                navigate('/error', {replace: true})
            }
            if (userData) {
                setAccommodation(userData.accommodation ?? false)
            }
        }
    }, [userData, loading, error])

    const handleSetForm = (val) => {
        setForm(val)
    }

    const sendData = async (e) => {
        e.preventDefault()
        executePost({
            data: compagno
        })
        if (!error) {
            setAccommodation(true)
        }
    }

    return (
        <Box sx={{background: '#fff'}}>
            <Container sx={{pb: 4}}>
                <img src={require('../assets/header.jpg')} alt={'header'} style={{width: '100%'}}/>
                {loading ?
                    <Box sx={{textAlign:'center'}}>
                        <CircularProgress/>
                    </Box>
                    :
                    accommodation ?
                        <Box sx={{py:12}}>
                            <Typography textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
                                Grazie per il feedback, i tuoi dati sono stati inviati con successo!
                            </Typography>
                        </Box>
                        :
                        <form onSubmit={sendData}>
                            <Typography textAlign={'center'} variant={'h6'} fontWeight={'bold'}
                                        sx={{py: 2}}>
                                Indica il nominativo della persona con cui vorrai condividere la camera:
                            </Typography>
                            <Container maxWidth={'sm'}>
                                <Stack spacing={2}>
                                    {
                                        Object.keys(compagno).map((key, index) =>
                                            <TextField required
                                                       type={key === 'emailCompagno' ? 'email' : 'text'}
                                                       fullWidth
                                                       value={compagno[key]}
                                                       variant={'standard'}
                                                       label={key === 'emailCompagno' ? 'Email' : key === 'nomeCompagno' ? 'Nome' : 'Cognome'}
                                                       onChange={(e) => setCompagno({
                                                           ...compagno,
                                                           [key]: e.target.value
                                                       })}/>
                                        )
                                    }
                                </Stack>
                                <Box sx={{pt: 4, textAlign: 'center'}}>
                                    {sending ? <CircularProgress/> : <Button variant={'contained'}
                                             sx={{width: 'min(25rem, 100%)', fontSize: '1.4rem'}}
                                             type={'submit'}>
                                        Invia
                                    </Button>}
                                </Box>
                            </Container>
                        </form>
                }
            </Container>
            <Footer/>
        </Box>
    )
}
