import React from "react";
import {Website} from "../layouts/Website";
import {Box, Container, Typography} from "@mui/material";
import {homeMap} from "../assets/data/homeMap";
import {HomeTextBlock} from "../components/HomeTextBlock";

export const Home = () => {
  return(
      <Website>
          <Box sx={{width:'100%'}}>
              <img style={{width:'100%'}} src={require('../assets/images/home-header.jpg')} alt={'home-header'}/>
          </Box>
          <Container sx={{py:2}}>
              {
                    homeMap.map((part, index) =>
                        <Box key={part.title} sx={{pb:5}} key={part.title}>
                            <Typography variant={'h4'} fontWeight={'bold'} sx={{pb:2}}>
                                {part.title}
                            </Typography>
                            {
                                part.sections.map((section, index) =>
                                    <HomeTextBlock section={section} key={section.title} direction={index % 2 === 0 ? 'row' : 'row-reverse'}/>
                                )
                            }
                        </Box>
                    )
              }
          </Container>
      </Website>
  )
}
