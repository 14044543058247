import React from "react";
import {Box, Button, Container, Stack, Typography} from "@mui/material";

export const Footer = () => {
    return (
        <Box sx={{background:'#EF4231', py:3, color:'white !important'}}>
            <Container>
                <Stack direction={{xs:'column', md:'row'}} justifyContent={'space-between'} alignItems={{xs:'start', md:'end'}}>
                    <Box>
                        <Typography variant={'h4'} fontWeight={'bold'}>
                            MYKONOS
                        </Typography>
                        <Typography variant={'h6'} color={'black'} fontWeight={'bold'}>
                            21-22-23 settembre 2023
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant={'h6'} fontWeight={'bold'}>
                            Segreteria Organizzativa
                        </Typography>
                        <Typography variant={'h6'} >
                            <a href={'mailto:segreteria@edenredsalesmeeting2023.it'} target={'_blank'} style={{color:'white'}}>segreteria@edenredsalesmeeting2023.it</a>
                        </Typography>
                    </Box>
                </Stack>
            </Container>
        </Box>
    )
}
