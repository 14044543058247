export const formMap = [
    {
        id: 'provenienza',
        title: 'Indica città e provincia di provenienza (domicilio)',
        fields: [
            {
                id: 'cittaProvenienza',
                name: 'cittaProvenienza',
                label: 'Città di provenienza',
                type: 'text',
                validations: ["string", 'required'],
                required: true,
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio",
                }
            },
            {
                id: 'provinciaProvenienza',
                name: 'provinciaProvenienza',
                label: 'Provincia di provenienza',
                type: 'text',
                validations: ["string", 'required'],
                required: true,
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio",
                }
            }
        ]
    },
    {
        id: 'infoPersonali',
        title: 'Al fine di ultimare la prenotazione del volo e agevolare la procedura di check-in presso l’hotel, ti chiediamo di compilare i seguenti dati:',
        fields: [
            {
                id: 'nomeDocumento',
                name: 'nomeDocumento',
                label: 'Nome (come da documento)',
                type: 'text',
                validations: ['string', "required"],
                required: true,
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'cognomeDocumento',
                name: 'cognomeDocumento',
                label: 'Cognome (come da documento)',
                type: 'text',
                validations: ['string', "required"],
                required: true,
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'luogoNascita',
                name: 'luogoNascita',
                label: 'Luogo di nascita',
                type: 'text',
                validations: ['string', 'required'],
                required: true,
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio",
                }
            },
            {
                id: 'dataNascita',
                name: 'dataNascita',
                label: 'Data di nascita',
                type: 'date',
                validations: ["date", "nullable", 'required', 'typeError', 'max'],
                required: true,
                precompiled: false,
                disableFuture: true,
                params: {
                    required: "Questo campo è obbligatorio",
                    typeError: "La data di nascita non è valida",
                    max: [new Date(), "La data di nascita non può essere nel futuro"]
                }
            },
            {
                id: 'tipoDocumento',
                name: 'tipoDocumento',
                label: 'Tipologia documento di viaggio',
                type: 'select',
                validations: ["string", 'required'],
                required: true,
                precompiled: false,
                values: [
                    {
                        value: "passaporto",
                        label: "Passaporto in corso di validità"
                    },
                    {
                        value: "cartaIdentita",
                        label: "Carta d'identità in corso di validità"
                    }
                ],
                params: {
                    required: "Questo campo è obbligatorio",
                }
            },
            {
                id: 'numeroDocumento',
                name: 'numeroDocumento',
                label: 'N. documento',
                type: 'text',
                validations: ["string", 'required'],
                required: true,
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio",
                }
            },
            {
                id: 'dataEmissione',
                name: 'dataEmissione',
                label: 'Data di emissione',
                type: 'date',
                validations: ["date", "nullable", 'required', 'typeError', 'max'],
                required: true,
                precompiled: false,
                disableFuture: true,
                params: {
                    required: "Questo campo è obbligatorio",
                    typeError: "La data di emissione non è valida",
                    max: [new Date(), "La data di emissione non può essere nel futuro"],
                }
            },
            {
                id: 'dataScadenza',
                name: 'dataScadenza',
                label: 'Data di scadenza',
                type: 'date',
                validations: ["date", "nullable", 'required', 'typeError', 'min'],
                required: true,
                precompiled: false,
                disablePast: true,
                params: {
                    required: "Questo campo è obbligatorio",
                    typeError: "La data di scadenza non è valida",
                    min: [new Date(), "La data di scadenza non può essere nel passato"],
                }
            }
        ]
    },
    {
        id: 'privacy',
        name: "privacy",
        title: '',
        subtitle: "",
        fields: [
            {
                id: 'privacy',
                name: 'privacy',
                label: <span>Ho letto e compreso termini e condizioni per il trattamento dei miei dati personali riportati nell’<a
                    href={'/Informativa_SalesMeeting2023.pdf'} target={'_blank'}>informativa privacy</a></span>,
                type: 'checkbox',
                validations: ['bool', "required", "oneOf"],
                required: true,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 12
                },
                params: {
                    oneOf: [
                        [true], "Questo campo è obbligatorio"
                    ]
                }
            }
        ]
    }
]
