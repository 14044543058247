import {Box, Typography} from "@mui/material";

export const voliMap = [
    {
        compagnia: "easyjet",
        content:
            <Typography>
                <b>Volo andata U2 3663</b>
                <br/>
                21 Settembre | partenza ore 06:00 da Milano Malpensa - arrivo ore 09:30 a Mykonos
                <br/>
                <br/>
                <b>Volo ritorno U2 3666</b>
                <br/>
                23 Settembre | partenza ore 15:40 da Mykonos - arrivo ore 17:25 a Milano Malpensa
                <br/>
                <br/>
            </Typography>,
        policyBagaglio:
            <Box>
                <Typography component={'div'}>
                    Potrai portare con te un bagaglio a mano da riporre sotto il sedile anteriore e un bagaglio in stiva, seguendo le specifiche indicazioni sotto riportate:
                    <ul>
                        <li>
                            Bagaglio a mano: 1 pezzo, max 15 kg, dimensioni 45 cm (altezza) x 36 cm (larghezza) x 20 cm (profondità) ruote e maniglie comprese<br/>
                            *Ricorda che i liquidi devono essere trasportati in contenitori di capacità non superiore a 100 ml.
                        </li>
                        <li>
                            Bagaglio in stiva: 1 pezzo, max 15 kg, dimensione massima di 275 cm (altezza + larghezza + profondità) ruote e maniglie comprese
                        </li>
                    </ul>
                </Typography>
            </Box>,
        policyLink: "https://www.easyjet.com/it/aiuto/bagaglio/bagaglio-a-mano-e-bagaglio-da-stiva"
    },
    {
        compagnia: "ita airways",
        content:
            <Typography>
                <b>Volo andata AZ 9056</b>
                <br/>
                21 Settembre | partenza ore 08:30 da Milano Malpensa - arrivo ore 12:05 a Mykonos
                <br/>
                <br/>
                <b>Volo ritorno AZ 9057</b>
                <br/>
                23 Settembre | partenza ore 16:50 da Mykonos - arrivo ore 18:55 a Milano Malpensa
                <br/>
                <br/>
                <u>*Trattandosi di voli charter ITA Airways, sia in andata sia al ritorno, gli orari indicati sono soggetti a variazione da parte della compagnia aerea che effettua la programmazione degli operativi.</u>
                <br/><br/>
            </Typography>,
        policyBagaglio:
            <Box>
                <Typography component={'div'}>
                    Potrai portare con te un bagaglio a mano da riporre sotto il sedile anteriore e un bagaglio in stiva, seguendo le specifiche indicazioni sotto riportate:
                    <ul>
                        <li>
                            Bagaglio a mano: 1 pezzo, max 8 kg, dimensioni 55 cm (altezza) x 35 cm (larghezza) x 25 cm (profondità) ruote e maniglie comprese<br/>
                            *Ricorda che i liquidi devono essere trasportati in contenitori di capacità non superiore a 100 ml.
                        </li>
                        <li>
                            Bagaglio in stiva: 1 pezzo, max 15 kg, dimensione massima di 158 cm (altezza + larghezza + profondità) ruote e maniglie comprese
                        </li>
                    </ul>
                </Typography>
            </Box>,
        policyLink: "https://www.ita-airways.com/it_it/fly-ita/baggage.html"
    },
    {
        compagnia: "volotea",
        content:
            <Typography>
                <b>Volo andata V7 1610</b>
                <br/>
                21 Settembre | partenza ore 09:15 da Napoli Capodichino - arrivo ore 12:15 a Mykonos
                <br/>
                <br/>
                <b>Volo ritorno V7 1611</b>
                <br/>
                23 Settembre | partenza ore 10:30 da Mykonos - arrivo ore 11:30 a Napoli Capodichino
                <br/><br/>
            </Typography>,
        policyBagaglio:
            <Box>
                <Typography component={'div'}>
                    Potrai portare con te un bagaglio a mano da riporre sotto il sedile anteriore e un bagaglio in stiva, seguendo le specifiche indicazioni sotto riportate:
                    <ul>
                        <li>
                            Bagaglio a mano: 1 pezzo, max 10 kg, dimensioni 40 cm (altezza) x 30 cm (larghezza) x 20 cm (profondità) ruote e maniglie comprese<br/>
                            *Ricorda che i liquidi devono essere trasportati in contenitori di capacità non superiore a 100 ml.
                        </li>
                        <li>
                            Bagaglio in stiva: 1 pezzo, max 10 kg, dimensioni 55 cm (altezza) x 40 cm (larghezza) x 20 cm (profondità) ruote e maniglie comprese
                        </li>
                    </ul>
                </Typography>
            </Box>,
        policyLink: "https://www.volotea.com/it/bagaglio/"
    }
]
