import React from 'react';
import {Box, Card, CardContent, CardMedia, Typography} from "@mui/material";

export const CardProgramma = ({day, index}) => {
    return (
        <Card sx={{width: '100%', height: '100%', border:'1px solid #dedede'}}>
            <CardMedia>
                <img style={{width: '100%'}} src={require(`../../assets/images/${day.image}`)}/>
            </CardMedia>
            <CardContent>
                <Box sx={{borderLeft: '3px solid #2F6CB4', px:2, py:1}}>
                    <Typography color={'primary'} variant={'h6'} fontWeight={'bold'}>
                        {index + 1}° giorno
                    </Typography>
                    <Typography>
                        {day.day}
                    </Typography>
                </Box>
                <Box sx={{pt:3}}>
                    {day.sections.map((section, index) =>
                        <Box key={index}>
                            <Typography variant={'h6'} color={'#2F6CB4'} fontWeight={'bold'}>
                                {section.title}
                            </Typography>
                            <ul style={{marginTop:'0.3rem'}}>
                                {section.activities.map((item, index) =>
                                    <li key={index} style={{paddingBlock:'0.3rem'}}>
                                        <Typography variant={'body1'}>
                                            {item}
                                        </Typography>
                                    </li>
                                )}
                            </ul>
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    )
}
