import './App.css';
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import {Register} from "./pages/Register";
import {Error} from "./pages/Error";
import {Accommodation} from "./pages/Accommodation";
import {Website} from "./layouts/Website";
import {ProgrammaDiViaggio} from "./pages/ProgrammaDiViaggio";
import {HotelEVoli} from "./pages/HotelEVoli";
import {InformazioniUtili} from "./pages/InformazioniUtili";
import {DressCode} from "./pages/DressCode";
import {Contacts} from "./pages/Contacts";
import {Phone} from "./pages/Phone";
import {FotoEVideo} from "./pages/FotoEVideo";

function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/foto-e-video" element={<FotoEVideo/>}/>
                <Route path="/phone/:hash" element={<Phone/>}/>
                <Route path="/programma" element={<ProgrammaDiViaggio/>}/>
                <Route path="/hotel-e-voli" element={<HotelEVoli/>}/>
                <Route path="/informazioni-utili" element={<InformazioniUtili/>}/>
                <Route path="/dress-code" element={<DressCode/>}/>
                <Route path="/contatti" element={<Contacts/>}/>
                <Route path={'/register/:hash'} element={<Register/>}/>
                <Route path={'/accommodation/:hash'} element={<Accommodation/>}/>
                <Route path={'/error'} element={<Error/>}/>
            </Routes>
        </div>
    );
}

export default App;
