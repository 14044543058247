import React, {useEffect} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    InputAdornment,
    Snackbar,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {Footer} from "../components/Footer";
import * as yup from "yup";
import {useFormik} from "formik";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationScheme = yup.object({
    phone: yup
        .string()
        .required('Inserisci il tuo numero di telefono')
        .min(9, 'Inserisci un numero di telefono valido')
        .max(10, 'Inserisci un numero di telefono valido'),
    repeatPhone: yup
        .string()
        .required('Inserisci il tuo numero di telefono')
        .min(9, 'Inserisci un numero di telefono valido')
        .max(10, 'Inserisci un numero di telefono valido')
        .oneOf([yup.ref('phone'), null], 'I numeri di telefono non coincidono')
})

export const Phone = () => {
    const {hash} = useParams()
    const navigate = useNavigate()
    const [sending, setSending] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [failed, setFailed] = React.useState(false)
    const [{data: userData, loading, error}, refetch] = useAxios(`${API_URL}users/${hash}/info`, {manual: false})
    const formik = useFormik({
        initialValues: {
            phone: '',
            repeatPhone: ''
        },
        validationSchema: validationScheme,
        onSubmit: async values => {
            setSending(true)
            await axios.put(`${API_URL}users/${hash}/info`, {phone: values.phone})
                .then(res => {
                  setSuccess(true)
                })
                .catch(err => {
                    setFailed(true)
                })
            setSending(false)
        }
    })

    useEffect(() => {
        if(!loading) {
            if (error) {
                navigate('/error', {replace: true})
            }
            if (userData.phone && userData.phone!=='') {
                setSuccess(true)
            }
        }
    }, [userData, loading, error])

    return (
        <Box sx={{background:'#F04131', minHeight:'100vh'}}>
            <Box sx={{background: '#fff'}}>
                <Snackbar open={failed} autoHideDuration={6000}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        C'è stato un errore, si prega di riprovare
                    </Alert>
                </Snackbar>
                <Container sx={{pb: 12}}>
                    <img src={require('../assets/header.jpg')} alt={'header'} style={{width: '100%'}}/>
                    <Container sx={{py: 4}} maxWidth={'sm'}>
                        {
                            loading ?
                                <Box sx={{textAlign:'center'}}>
                                    <CircularProgress/>
                                </Box>
                                :
                                success ?
                                    <Typography sx={{py:10}} textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
                                        Grazie per il feedback, i tuoi dati sono stati inviati con successo!
                                    </Typography>
                                    :
                                    <Box>
                                        <Typography textAlign={'center'} variant={'h6'} fontWeight={'bold'}>
                                            Richiesta numero di telefono
                                        </Typography>
                                        <Typography sx={{py:2}}>
                                            Ciao {userData?.nome ?? ''},<br/>
                                            in vista della partenza, ti chiediamo la cortesia di comunicarci un tuo contatto telefonico al fine di gestire eventuali comunicazioni last minute durante i giorni dell’evento.
                                        </Typography>
                                        <form onSubmit={formik.handleSubmit}>
                                            <Stack spacing={2} sx={{py:3}}>
                                                <TextField
                                                    fullWidth
                                                    id="phone"
                                                    name="phone"
                                                    label="Numero di telefono"
                                                    type="text"
                                                    value={formik.values.phone}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                                    helperText={formik.touched.phone && formik.errors.phone}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position={'start'}><Typography>+39</Typography></InputAdornment>,
                                                    }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    id="repeatPhone"
                                                    name="repeatPhone"
                                                    label="Ripeti il numero di telefono"
                                                    type="text"
                                                    value={formik.values.repeatPhone}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.repeatPhone && Boolean(formik.errors.repeatPhone)}
                                                    helperText={formik.touched.repeatPhone && formik.errors.repeatPhone}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position={'start'}><Typography>+39</Typography></InputAdornment>,
                                                    }}
                                                />
                                            </Stack>
                                            <Box sx={{textAlign:'center'}}>
                                                {sending ? <CircularProgress/> :
                                                        <Button variant={'contained'} type={'submit'}>
                                                    invia
                                                </Button>}
                                            </Box>
                                        </form>
                                    </Box>
                        }
                    </Container>
                </Container>
                <Footer/>
            </Box>
        </Box>
    )
}
