export const dressMap = [
    {
        title: 'CONSIGLI GENERALI',
        description:"Situato in una posizione privilegiata sulla spiaggia di Elia, l'hotel Myconian Villa Collection sorge su una montagna, i suoi spazi sono dislocati a diverse altezze con salite e discese digradanti fino al mare. Suggeriamo di prevedere per tutte le giornate un abbigliamento fresco, comodo e confortevole. Non dimenticare costume da bagno, creme solari, occhiali da sole e simili, da utilizzare in piscina o in spiaggia."
    },
    {
        title: 'COOL & GLAMOUR GREEK NIGHT',
        description:"Sarà una serata informale, cenerai in un ristorante all’aperto e il dopocena potrai divertirti nei locali del centro di Mykonos. Ti consigliamo però di non dimenticare un maglioncino o una giacca perché, si sa, nelle isole greche, soffia sempre un po' di vento. Come vedi, non c’è un tema ben preciso, quindi puoi dare libero sfogo alla tua personalità!"
    },
    {
        title: 'WHITE PARTY',
        description:"Consigliamo un outfit con prevalenza di tonalità bianche o similari (panna, avorio, latte etc.). Puoi indossare un accessorio come un gilet, un cappello, un foulard, una cravatta o fare un look completamente white... scegli tu quello che preferisci."
    },
]
