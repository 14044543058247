import React from "react";
import {Website} from "../layouts/Website";
import {PageTitle} from "../components/shared/PageTitle";
import {Container, Grid, Card, CardMedia} from "@mui/material";
import {programMap} from "../assets/data/programMap";
import {CardProgramma} from "../components/ProgrammaDiViaggio/CardProgramma";

export const ProgrammaDiViaggio = () => {
    return (
        <Website>
            <Container sx={{pb:2, pt:6}}>
                <PageTitle title={'Programma di viaggio'} image={'icon-program'}/>
                <Grid container spacing={2} sx={{pt:2}}>
                    {
                        programMap.map((day, index) =>
                            <Grid item xs={12} md={6} lg={4} key={index}>
                                <CardProgramma day={day} index={index}/>
                            </Grid>
                        )}
                </Grid>
            </Container>
        </Website>
    )
}
