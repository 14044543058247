import React from "react";
import {Website} from "../layouts/Website";
import {Box, Container, Typography} from "@mui/material";
import {PageTitle} from "../components/shared/PageTitle";
import {dressMap} from "../assets/data/dressMap";

export const DressCode = () => {
    return (
        <Website>
            <Container maxWidth={'xxl'} sx={{pb: 2, pt: 6}}>
                <PageTitle title={'Dress Code'} image={'icon-dress'}/>
                {
                    dressMap.map((dress, index) =>
                        <Box>
                            <Typography color={index === 0 ? '#2F6CB4' : 'black'}
                                        fontWeight={index === 0 ? 'bold' : ''}
                                        variant={index === 0 ? "h6" : 'body1'}
                                        sx={{textDecoration: index === 0 ? 'none' : 'underline'}}
                            >
                                {index === 0 ? "COSA METTERE IN VALIGIA…" : index + "° GIORNO"}
                            </Typography>
                            <ul>
                                <li>
                                    <Typography>
                                        <b>{dress.title}</b>
                                        <br/>
                                        {dress.description}
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    )}
                <Container>
                    <img src={require('../assets/images/dress-code.jpg')} alt={'dress-code'} style={{width:'100%'}}/>
                </Container>
            </Container>
        </Website>
    )
}
