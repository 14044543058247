import React from 'react';
import {Box, Grid, Stack, Typography} from "@mui/material";

export const HomeTextBlock = ({section, direction}) => {

    return (
        <Box sx={{py:3}}>
            <Grid container direction={{xs:'column', md:direction}} spacing={{xs:1, md:4}} alignItems={'center'}>
                {section.image && <Grid item xs={12} md={6} sx={{flexGrow: 1}}>
                    <img style={{width:'100%', borderRadius:'0.6rem'}} src={require(`../assets/images/${section.image}.jpg`)}/>
                </Grid>}
                <Grid item xs={12} md={section.image ? 6 : 12} sx={{flexGrow:1}}>
                    <Typography variant={'h6'} color={'#2F6CB4'} fontWeight={'bold'} textTransform={'uppercase'}>
                        {section.title}
                    </Typography>
                    <Box sx={{py:2}}>
                        <Box sx={{borderBottom:'3px solid #2F6CB4', width:'4rem'}}/>
                    </Box>
                    <Typography variant={'body1'}>
                        {section.description}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}
